import React, { Fragment, Component } from 'react';
//import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.css';
import './theme/css/app.min.css';
import './theme/css/dataTables.bootstrap4.min.css';
import './theme/style.scss';
import './App.scss';
import './theme/js/scripts.js';
import { BrowserRouter,Switch } from "react-router-dom";
import { ToastContainer, Zoom } from 'react-toastify';
import { Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { SecurePage } from './pages/SecurePage';
import Login from './components/auth/Login';
import LoginCallback from './components/auth/LoginCallback';
function App() { 
  return (
    <Fragment>     
      <BrowserRouter >      
        <React.StrictMode>
          <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/login-callback" component={LoginCallback} />
          <Route  path='/' component={SecurePage} />         
          </Switch>
        </React.StrictMode>
      </BrowserRouter>
      <ToastContainer transition={Zoom} />
    </Fragment>
  );
}


export default App

