import { HttpWrapper, AppConfig } from '../../../../../core'
import { LinkResourcesResponseModel, FileUploadModel, FileUploadResponseModel } from '../../../../../shared/models';
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { CustomerFaviconListResponseModel, CustomerFaviconDetailModel, PostCustomerFaviconModel } from '../models';
export class CustomerFaviconService {
    private wrapper: HttpWrapper;
    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getCustomerFaviconList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<CustomerFaviconListResponseModel>(AppConfig.apiEndpoint + '/v1/customer_favicons/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getCustomerFaviconById(id: number) {
      return this.wrapper.get<CustomerFaviconDetailModel>(AppConfig.apiEndpoint + '/v1/customer_favicons/' + id);
    }  
  
    postCustomerFavicon(data:FileUploadModel) {
      return this.wrapper.post<FileUploadResponseModel>(AppConfig.apiEndpoint + '/v1/customer_favicons',data);
    }  
      
    getLinkedResourcesById(id: number) {
      return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/customer_favicons/' + id + '/linked_resources');
    } 
    
  
    exportCustomerFavicon(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/CustomerFavicons/export', exportData);
    }
  
  
  }