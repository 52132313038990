import React, { Component, Fragment } from 'react'
import { ResetPasswordResponseModel, UserDetailModel } from './models';
import { SubscriptionsModel } from './models/subscriptions.model'
import { UserService } from './services';
import { HttpResponse, AppConfig } from '../../core';
import { ConvertDateTime, HandleNotFoundResponse, ShowSuccessMessage } from './../../shared/helpers'
import { DropdownItemModel } from '../../shared/models';
import { NavLink } from "react-router-dom";
import { UserTabs } from '../user/UserTabs'
import { IsAuthorize } from '../../shared/authorization/check-access';
import { Role } from '../../shared/authorization/enums';
import { NotFoundResponseArea } from '../../shared/enums';
import { Loader } from '../../shared/loaders';
interface IState {
    result?: UserDetailModel;
    isLoading?: boolean;
    resetPassword?: string;
    isResetPassword?: boolean;
}
export class UserDetail extends Component<any, IState> {
    private userService: UserService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.userService = new UserService();
        this.logOut = this.logOut.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.hideAlertData = this.hideAlertData.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            account: {
                text: "",
                value: 0
            },
            account_id: 0,
            billable: false,
            deactivated: false,
            demo: false,
            email: "",
            failed_attempts: 0,
            id: 0,
            name: "",
            needs_setup: false,
            phone: "",
            reports: [],
            role: {
                text: "",
                value: 0
            },
            role_id: 0,
            sign_in_count: 0,
            staq_admin: false,
            subscriptions: [],
            title: "",
            created_at: new Date(),
            last_sign_in_at: new Date(),
            reset_password_sent_at: new Date(),
            updated_at: new Date(),
            current_sign_in_at: new Date(),
            current_sign_in_ip: "",
            last_sign_in_ip: ""
        },
        isLoading: false,
        resetPassword: "",
        isResetPassword: false

    }

    componentDidMount() {
        this.loadData(this.props.match.params.id);
    }
 
    componentWillReceiveProps(nextprops) {
        this.loadData(nextprops.match.params.id);
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData(id) {
        this.setLoading(true);
        this.userService.getUserById(id)
            .then((res: HttpResponse<UserDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.Users, this.props)
            });
    }

    hideAlertData() {
this.setState({isResetPassword:false});
    }


    resetPassword() {
        this.setLoading(true);
        this.userService.resetPasswordById(this.props.match.params.id)
            .then((res: HttpResponse<ResetPasswordResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({ resetPassword: res.result.reset_password_token, isResetPassword: true },()=>{
                        this.props.history.push("/user/detail/"+this.props.match.params.id);
                    });                   
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.Users, this.props)
            });
    }

    logOut() {
        localStorage.clear();
    }

    render() {
        return (
            <Fragment>
                {this.state.result?.id == this.props.match.params.id && <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Details for User '{this.state.result?.name === "" ? this.state.result?.email : this.state.result?.name}'</h4>
                    </div>
                    {this.state.isResetPassword && <div className="alert alert-sm alert-success alert-dismissible">

                        <button onClick={this.hideAlertData} type="button" className="close">&times;</button>
                       Use the following link to reset User #{this.props.match.params.id}'s password. https://pentesting.test.staq.com/users/password/edit?reset_password_token={this.state.resetPassword}
                    </div>}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <UserTabs id={this.props.match.params.id} url="/user/detail/" />
                                    {!this.state.isLoading && <>
                                        <h5 className="mb-3">Basic Info</h5>
                                        <div className="row">
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header"><b>Name</b></div>
                                                    <div className="card-body">{this.state.result?.name}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header"><b>Email</b></div>
                                                    <div className="card-body">{this.state.result?.email}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header"><b>Phone</b></div>
                                                    <div className="card-body">{this.state.result?.phone}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header"><b>Title</b></div>
                                                    <div className="card-body">{this.state.result?.title}</div>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header"><b>Role</b></div>
                                                    <div className="card-body">
                                                        <NavLink to={"/role/detail/" + this.state.result?.role.value}>
                                                            {this.state.result?.role.text}</NavLink></div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-silver shadow-sm">
                                                    <div className="card-header"><b>Account</b></div>
                                                    <div className="card-body"><NavLink to={"/account/detail/" + this.state.result?.account.value}>{this.state.result?.account.text}</NavLink></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Failed Attempts</div>
                                                    <div className="card-body">{this.state.result?.failed_attempts}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Created At</div>
                                                    <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Updated At</div>
                                                    <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                                </div>
                                            </div>

                                            {this.state.result?.deactivated &&
                                                <div className="col">
                                                    <div className="card card-info shadow-sm">
                                                        <div className="card-header font-weight-bold">Deactivated</div>
                                                        <div className="card-body"><i className="fas fa-check text-success text2"></i></div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.result?.billable &&
                                                <div className="col">
                                                    <div className="card card-info shadow-sm">
                                                        <div className="card-header font-weight-bold">Billable</div>
                                                        <div className="card-body"><i className="fas fa-check text-success text2"></i></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Last Sign In At</div>
                                                    <div className="card-body">{ConvertDateTime(this.state.result?.last_sign_in_at, 'LLL')}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Last Sign In IP</div>
                                                    <div className="card-body">{this.state.result?.last_sign_in_ip}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Current Sign In At</div>
                                                    <div className="card-body">{ConvertDateTime(this.state.result?.current_sign_in_at, 'LLL')}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Current Sign In IP</div>
                                                    <div className="card-body">{this.state.result?.current_sign_in_ip}</div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="card card-info shadow-sm">
                                                    <div className="card-header font-weight-bold">Sign In Count</div>
                                                    <div className="card-body">{this.state.result?.sign_in_count}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.result?.staq_admin &&
                                                <div className="col">
                                                    <div className="card card-silver shadow-sm">
                                                        <div className="card-header font-weight-bold">Staq Admin</div>
                                                        <div className="card-body"><i className="fas fa-check text-success text2"></i></div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.result?.needs_setup &&
                                                <div className="col">
                                                    <div className="card card-silver shadow-sm">
                                                        <div className="card-header font-weight-bold">Need Setup</div>
                                                        <div className="card-body"><i className="fas fa-check text-success text2"></i></div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.result?.demo &&
                                                <div className="col">
                                                    <div className="card card-silver shadow-sm">
                                                        <div className="card-header font-weight-bold">Demo</div>
                                                        <div className="card-body"><i className="fas fa-check text-success text2"></i></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            {IsAuthorize([Role.Sales, Role.AM, Role.TAM, Role.Dev, Role.Support ,Role.Manager]) && <div className="col">
                                                <h6>Impersonation</h6>
                                                <a href={AppConfig.ImpersonationUrl + this.props.match.params.id} className="btn btn-success" style={{ color: "white" }}> Impersonate User {this.state.result?.id}</a>
                                                {/* <NavLink to={'/login'} className="btn btn-success" style={{color:"white"}} onClick={this.logOut}> Impersonate User {this.state.result?.id}</NavLink> */}
                                            </div>}
                                            <div className="col">
                                                <h6>Password </h6>
                                                <button type="button" onClick={this.resetPassword} className="btn btn-success">Generate Password</button> Last Generated:&nbsp;{ConvertDateTime(this.state.result?.reset_password_sent_at, 'LLL')}
                                                <p className=" text-muted small" >Generate a temporary URL to reset the password. This URL will expire in one day. </p>
                                            </div>
                                        </div>

                                        {this.state.result?.subscriptions.length != 0 && <div className="row">
                                            <div className="col-md-12  mt-3"> <h6>Subscription</h6></div>
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-md table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Subscription category</th>
                                                                <th scope="col">Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.result?.subscriptions.map((item: SubscriptionsModel, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.subscription_category_name}	</td>
                                                                        <td>{item.description}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.reports.length != 0 && <div className="row">
                                            <div className="col-md-12 mt-3"> <h6>Reports</h6></div>
                                            <div className="col-md-12"><div className="row">
                                                {this.state.result?.reports.map((item: DropdownItemModel, index) => {
                                                    return (
                                                        <div className="m-1 " key={item.value}>
                                                            <NavLink to={"/report/detail/" + item.value} className="badge badge-light badge-c text-left"><span className="font-weight-bold">{index + 1}: </span> {item.text}</NavLink>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            </div>
                                        </div>}
                                    </>}
                                    {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </Fragment>
        )
    }
}
