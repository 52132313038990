import React, { Component, Fragment } from 'react'
import { AccountDetailModel } from './models/account.detail.model'
import { AccountService } from './services/account.service';
import { HttpResponse } from '../../core';
import { NavLink } from "react-router-dom";
import { ConvertDateTime, GetDays, HandleNotFoundResponse, ShowErrorMessage, ShowSuccessMessage } from '../../shared/helpers';
import { DeactivateUsersPostModel, UserListModel } from './models';
import AccountTabs from './AccountTabs';
import { AdminModel } from './models/admin.model';
import { DatabaseEngineModel } from './models/database.engine.model';
import { DropdownItemModel } from '../../shared/models';
import { NotFoundResponseArea } from '../../shared/enums';
import { Loader } from '../../shared/loaders';

interface IState {
    result?: AccountDetailModel;
    isLoading?: boolean;
    isAllChecked?: boolean; 
    Ids?: number[];
    selectedIds?: number[];
}
export class AccountDetail extends Component<any, IState> {
    private service: AccountService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new AccountService();
    }
    initialState: Partial<IState> = {
        result: {
            active: false,
            backup_day: 0,
            user_billable: 0,
            contract_date: new Date(),
            contract_discount: 0,
            contract_renewal_date: new Date(),
            contract_unit_count: 0,
            contract_unit_price: 0,
            data_retention_days: 0,
            database_engine: {
                text: "",
                value: 0
            },
            db_engine_id: 0,
            db_engine_info: [],
            estimate_billable_unit_count: 0,
            id: 0,
            logo_url: "",
            name: "",
            priority: 0,
            sftp_account_name: "",
            state: "",
            users: [],
            created_at: new Date(),
            updated_at: new Date(),
            account_domains: [],
            admins: [],
            contract_billing_schedule: "",
            domain: "",
            favicon_url: "",
            notes: "",
            pause_scheduled_reports: false,
            report_schedule_start: "",
            report_schedule_stop: "",
            report_status: "",
            trial: false,
            url: ""
        },
        isLoading: false,
        isAllChecked: false,
        Ids: [],
        selectedIds: [],
    }

    componentDidMount() {
        this.loadData();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        this.service.getAccountById(this.props.match.params.id)
            .then((res: HttpResponse<AccountDetailModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.Accounts,this.props) 
            });
    }
    deactivateUsers = () => {
        let data: DeactivateUsersPostModel = {
            user_ids: this.state.selectedIds
        }
        if (data.user_ids?.length == 0) {
            return ShowErrorMessage("please select atleast one user.");
        }
        else {            
            this.service.deactivateUsersById(this.props.match.params.id, data)
                .then((res: HttpResponse<any>) => {
                    if (res && res.result) {
                        ShowSuccessMessage("users successfully deactivated.");
                        this.setState({ selectedIds: [] })
                        this.loadData();
                    }
                })
        }

    }
    onSelectionChange(e: any) {
        // current array of ids
        let ids = this.state.Ids
        if (e.target.name === "all") {
            ids = []
            if (e.target.checked) {
                this.state.result?.users.forEach(p => { p.isChecked = true })
                this.setState({ result: this.state.result, isAllChecked: true, Ids: ids }, () => {
                    this.state.result?.users.forEach((p) => { ids?.push(p.id) })
                })
            }
            else {
                this.state.result?.users.forEach(p => { p.isChecked = false })
                this.setState({ result: this.state.result, isAllChecked: false, Ids: ids })
            }
        }
        else {
            let isAll;
            let index;
            // let checkedIndex = this.state.result?.users.findIndex(p => p.id == e.target.value);
            // this.state.result?.users[checkedIndex]["isChecked"] = e.target.checked;
            this.state.result?.users.forEach(p => {
                if (p.id == e.target.value) {
                    { p.isChecked = e.target.checked }
                }
            }
            )
            if (e.target.checked) {
                ids?.push(+e.target.value)
                if (this.state.result?.users.filter(p => p.isChecked == true).length === this.state.result?.users.length) {
                    isAll = true;
                }
            } else {
                // remove the value from the unchecked checkbox from the array
                index = ids?.indexOf(+e.target.value)
                ids?.splice(index, 1)
                isAll = false;
            }
            this.setState({ result: this.state.result, isAllChecked: isAll })
        }
        // update the state with the new array of ids       
        this.setState({ selectedIds: ids })
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Account '{this.state.result?.name === "" ? "New Account" : this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <AccountTabs id={this.props.match.params.id} url="/account/detail/" />
                                {!this.state.isLoading && <>  
                                <h5 className="mb-3">Basic Info</h5>
                                <div className="row">
                                    {this.state.result?.name && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Name</b>
                                            </div>
                                            <div className="card-body">
                                             <img src={this.state.result?.favicon_url} height="16" /> {this.state.result?.name}
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>License Type</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.state}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Report Process Status</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.report_status}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Backup Day of the Week</b>
                                            </div>
                                            <div className="card-body">
                                                {GetDays(this.state.result?.backup_day)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Data Retention Days</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.data_retention_days}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Priority</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.priority}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.result?.active && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Active</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    }
                                    {this.state.result?.trial && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Trial</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    }
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Database Engine Id</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.db_engine_id}
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>


                                {this.state.result?.notes &&     <div className="row">
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Notes</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.notes}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <div className="row">
                                {this.state.result?.contract_date && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Contract Date</b>
                                            </div>
                                            <div className="card-body">
                                              {ConvertDateTime(this.state.result?.contract_date, 'LLL')}
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.result?.contract_renewal_date && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Contract Renewal Date</b>
                                            </div>
                                            <div className="card-body">
                                               {ConvertDateTime(this.state.result?.contract_renewal_date, 'LLL')}
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.contract_billing_schedule && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Contract Billing Schedule</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.contract_billing_schedule}
                                            </div>
                                        </div>
                                    </div>}
                                     <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Contract Discount</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.contract_discount}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Contract Unit Count</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.contract_unit_count}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Contract Unit Price</b>
                                            </div>
                                            <div className="card-body">
                                              {this.state.result?.contract_unit_price}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    {this.state.result?.logo_url &&  <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Logo</b>
                                            </div>
                                            <div className="card-body">
                                               <img src={this.state.result?.logo_url} height="30" />
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.url && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Homepage URL</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.url}
                                            </div>
                                        </div>
                                    </div>}
                                </div>

                                <div className="row">
                                {this.state.result?.estimate_billable_unit_count && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Estimate Billable Unit Count</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.estimate_billable_unit_count}
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Billable Users</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.user_billable}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.result?.database_engine && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Database Engine</b>
                                            </div>
                                            <div className="card-body">
                                            <NavLink to={"/database-engine/detail/" + this.state.result?.database_engine.value}>{this.state.result?.database_engine.text}</NavLink>
                                                </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="row">
                                {this.state.result?.sftp_account_name &&   <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>SFTP Account Name</b>
                                            </div>
                                            <div className="card-body">
                                               {this.state.result?.sftp_account_name}
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.report_schedule_start &&    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Report Schedule Start</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.report_schedule_start}
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.report_schedule_stop &&    <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Report Schedule Stop</b>
                                            </div>
                                            <div className="card-body">
                                                {this.state.result?.report_schedule_stop}
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.result?.pause_scheduled_reports && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Pause Scheduled Reports</b>
                                            </div>
                                            <div className="card-body"> <i className="fas fa-check text-success"></i>   Yes</div>
                                        </div>
                                    </div>
                                    }

                                </div>

                                {this.state.result?.account_domains.length != 0 && <div className="row">
                                    <div className="col"> 
                                    <div className="card card-silver shadow-sm">
                                            <div className="card-header"> <b>Email Domains</b></div>
                                            <div className="card-body">
                                            {this.state.result?.account_domains.map((item: DropdownItemModel, index) => {
                                            return (
                                                <span className="m-1 " key={item.value}>
                                                 {item &&  <NavLink to={"/account-domain/detail/" + item.value} className="badge badge-light"><span className="font-weight-bold">{index + 1}: </span> {item.text}</NavLink>}
                                                </span>
                                            );
                                        })}
                                    </div>   
                                    </div>
                                    </div>
                                   
                                        
                                    
                                </div>}

                               
                                <div className="row">
                                  <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Created At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.created_at, 'LLL')}</div>
                                        </div>
                                    </div>
                                    {this.state.result?.updated_at && <div className="col">
                                        <div className="card card-silver shadow-sm">
                                            <div className="card-header">
                                                <b>Updated At</b>
                                            </div>
                                            <div className="card-body">{ConvertDateTime(this.state.result?.updated_at, 'LLL')}</div>
                                        </div>
                                    </div>}
                                </div>
                                {this.state.result?.admins.length != 0 && <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <h5>Assigned STAQ Contacts</h5>
                                        <div className="table-responsive">
                                            <table className="table table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Team</th>
                                                        <th scope="col">Role</th>
                                                    </tr>
                                                </thead>
                                                 <tbody>
                                                    {this.state.result?.admins.map((item: AdminModel, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                 {item.name && <td> <NavLink to={"/admin/detail/" + item.id}>{item.name}</NavLink></td>}
                                                                {!item.name && <td></td>}
                                                                <td>{item.team}</td>
                                                                <td>{item.role}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> 
                                </div>}
                                {this.state.result?.users.length != 0 && <div className="row">
                                        <div className="col-md-12 mt-4">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div className="flex-fill">
                                                    <div className="row">
                                                        <div className="col-md-8"> <h4>Users</h4></div>                                                       
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-md-4">
                                                            <button type="button" className="btn btn-primary" onClick={this.deactivateUsers}> Deactivate Users</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th className=" text-center">
                                                                <div className="custom-checkbox custom-control">
                                                                    <input type="checkbox" className="custom-control-input"
                                                                        value="all" onChange={this.onSelectionChange.bind(this)} id="all" name="all" checked={this.state.isAllChecked} />
                                                                    <label htmlFor="all" className="custom-control-label">&nbsp;</label>
                                                                </div>

                                                            </th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Role</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Deactivated</th>
                                                            <th scope="col">Billable</th>
                                                            <th scope="col">Created At</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.result?.users.map((item: UserListModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-center">
                                                                        <div className="custom-checkbox custom-control">
                                                                            <input type="checkbox" className="custom-control-input"
                                                                                value={item.id || ''} checked={item.isChecked || false} onChange={this.onSelectionChange.bind(this)} id={"checkbox" + item.id} name={"checkbox" + item.id} />
                                                                            <label htmlFor={"checkbox" + item.id} className="custom-control-label">&nbsp;</label>
                                                                        </div>
                                                                    </td>
                                                                    {item.name && <td> <NavLink to={"/user/detail/" + item.id}>{item.name}</NavLink></td>}
                                                                    {!item.name && <td></td>}
                                                                    {item.role && <td> <NavLink to={"/role/detail/" + item.role.value}>{item.role.text}</NavLink></td>}
                                                                    {!item.role && <td></td>}
                                                                    <td>{item.email}</td>
                                                                    <td>{item.deactivated && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                        {!item.deactivated && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                    <td>{item.billable && <i className="fas fa-check text-success text1" title="&#x2713;" ></i>}
                                                                        {!item.billable && <i className="fas fa-times text-danger text1" title="&#x2718;"></i>} </td>
                                                                    <td>{ConvertDateTime(item.created_at, 'LLL')}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>}
                        </>}                 
                        {this.state.isLoading && <Loader loading={this.state.isLoading} />}         
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
