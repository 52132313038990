import React, { Component, Fragment } from 'react'
import { AppConfig, HttpResponse } from '../../../core';
import Modal from 'react-bootstrap/Modal';
import { NotFoundResponseArea } from '../../../shared/enums';
import { ConvertDateTime, GetUserInfo, HandleNotFoundResponse, ShowSuccessMessage } from '../../../shared/helpers';
import { CollectionMissionService } from './services/collection.mission.service';
import { NavLink } from "react-router-dom";
import { Loader } from '../../../shared/loaders';
import { RerunCollectionMissionPostModel, RerunCollectionMissionResponseModel } from '../../../shared/models';
import { CancelCustomCollectionMission } from '../custom-connection/models';
import { CollectionMissionResponseModel, NewCollectionJbsModel, TransitionsModel } from './models/collection.mission.response.model';
import moment from 'moment';
import CollectionMissionTaskFiles from './CollectionMissionTaskFiles';

interface IState {
    result?: CollectionMissionResponseModel;
    tasks?: any;
    isLoading?: boolean;
    rerunMissionDate?: Date;
    selectedTaskId: number;
    showMissionTaskModal: boolean;
}
export default class CollectionMission extends Component<any, IState> {
    private service: CollectionMissionService;
    constructor(props) {
        super(props);
        this.service = new CollectionMissionService();
        this.rerunMission = this.rerunMission.bind(this);
        this.cancelMission = this.cancelMission.bind(this);
        this.state = {
             result: {
                connection_type: "",
                created_at: null,
                datapoint_count: 0,
                earliest_data_at: null,
                id: 0,
                interactive: false,
                latest_data_at: null,
                mission_end_at:new Date(),
                mission_start_at: new Date(),
                rerun: false,
                scheduled_at: null,
                started_at: null,
                state: "",
                target_id: 0,
                target_type: "",
                triggered_by: "",
                updated_at: new Date(),
                verify_only: false,
                transitions: [],
                new_collection_jobs:[],
                connection:{
                    text:"",
                    value:0
                },
                message:"",
                target_name:""
    
            },
            tasks: [],
            selectedTaskId: 0,
            showMissionTaskModal: false,
            isLoading: false,
            rerunMissionDate: new Date()
        }
    }

    rerunMission(id) {
        let email = GetUserInfo().email;
        let data: RerunCollectionMissionPostModel = {
            collection_mission_id: id,
            rerun: true,
            triggered_by: email
        };
        this.service
            .rerunCollectionMissionById(data)
            .then((res: HttpResponse<RerunCollectionMissionResponseModel>) => {
                if (res && res.result) {
                    ShowSuccessMessage("Mission created for " + ConvertDateTime(this.state.rerunMissionDate, "LLL") + " UTC");
                    if (this.state.result?.connection_type == "Connection") {
                        this.props.history.push("/connection/detail/" + this.state.result?.connection.value);
                    }
                    if (this.state.result?.connection_type == "CustomConnection") {
                        this.props.history.push("/custom-connection/detail/" + this.state.result?.connection.value);
                    }
                }
            })
    }

    cancelMission(id) {
        if (this.state.result?.connection_type == "CustomConnection") {
        this.service
            .cancelCollectionMissionById(this.state.result?.connection.value, id)
            .then((res: HttpResponse<CancelCustomCollectionMission>) => {
                if (res && res.result) {
                    ShowSuccessMessage(res.result.mission_count + " missions and " + res.result.task_count + " tasks for this connection have been canceled.");
                    this.props.history.push("/custom-connection/detail/" + this.state.result?.connection.value);
                }
            })
        }
        if (this.state.result?.connection_type == "Connection") {
            this.service
            .cancConnectionelCollectionMissionById(this.state.result?.connection.value, id)
            .then((res: HttpResponse<CancelCustomCollectionMission>) => {
                if (res && res.result) {
                    ShowSuccessMessage(res.result.mission_count + " missions and " + res.result.task_count + " tasks for this connection have been canceled.");
                    this.props.history.push("/connection/detail/" + this.state.result?.connection.value);
                }
            })
        }
    }

    componentDidMount() {
        this.loadData();
        // this. getTask();
    }
    private setLoading(loading: boolean) {
        this.setState({ isLoading: loading });
    }
    loadData() {
        this.setLoading(true);
        console.log("Loading Mission");
        this.service.getCollectionMissionById(this.props.match.params.id)
            .then((res: HttpResponse<CollectionMissionResponseModel>) => {
                this.setLoading(false);
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
                
            })
            .then(()=>{
                console.log("Loading Tasks");
                this.service.getCollectionMissionTasks(this.props.match.params.id)
                .then((res: HttpResponse<any>) =>{
                    this.setState({
                        tasks: res.result
                    });
                })
                
            })
            .catch(ex => {
                if (this.state.result?.connection_type == "Connection") {
                    HandleNotFoundResponse(ex, NotFoundResponseArea.Connection, this.props)
                }
                if (this.state.result?.connection_type == "CustomConnection") {
                    HandleNotFoundResponse(ex, NotFoundResponseArea.CustomConnection, this.props)
                }
            });
    }

    handleMissionTaskClose = () => {
        this.setState({
            showMissionTaskModal: false
        })
    }

    handleTaskClick(taskId, e) {
        e.preventDefault();
        this.setState({
            selectedTaskId: taskId,
            showMissionTaskModal: true
        });
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Details for Collection Mission 'CollectionMission #{this.state.result?.id}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {!this.state.isLoading && <>
                                    <h5 className="mb-3">Basic Info</h5>
                                    <div className="row">
                                    {this.state.result?.connection && <div className="col-3">
                                    {this.state.result?.connection_type == "Connection" &&  <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Connection</b>
                                                </div>
                                                <div className="card-body">
                                                   <NavLink to={"/connection/detail/" + this.state.result?.connection.value}>{this.state.result?.connection.text}</NavLink>
                                                                  </div>
                                            </div>}
                                            {this.state.result?.connection_type == "CustomConnection" &&<div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Custom Connection</b>
                                                </div>
                                                <div className="card-body">
                                                <NavLink to={"/custom-connection/detail/" + this.state.result?.connection.value}>{this.state.result?.connection.text}</NavLink>
                                                </div>
                                            </div>}
                                        </div>}
                                        {this.state.result?.verify_only && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Verify Only</b>
                                                </div>
                                                <div className="card-body"> <i className="fas fa-check text-success"></i> Yes</div>
                                            </div>
                                        </div>
                                        }
                                      
                                      {this.state.result?.datapoint_count!=0 &&<div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Datapoint Count</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.datapoint_count}
                                                </div>
                                            </div>
                                        </div>}
                                      
                                    </div>

                              <div className="row">
                               {this.state.result?.state &&  <div className="col-12">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>State</b>
                                                </div>
                                                <div className="card-body">
                                                <a href={"https://papertrailapp.com/groups/8012632/events?q="+this.state.result?.id}>
                                                    {this.state.result?.state}</a>&nbsp;-&nbsp;
                                                    
                                                    {(this.state.result?.state=="new" || this.state.result?.state=="in_progress")&& <span>Data collection for {moment(this.state.result?.mission_start_at).format('ll')}
                                                    &nbsp;through &nbsp;{moment(this.state.result?.mission_end_at).format('ll')}&nbsp;
                                                    started at {moment(this.state.result?.updated_at).format('ll')}</span>}

                                                    {this.state.result?.state=="connected" && <span>A successful connection was made at {moment(this.state.result?.updated_at).format('ll')}</span>}

                                                    {this.state.result?.state=="collected" && <span> {this.state.result?.datapoint_count}&nbsp; rows were collected for {moment(this.state.result?.mission_start_at).format('ll')}
                                                    &nbsp;through &nbsp;{moment(this.state.result?.mission_end_at).format('ll')}&nbsp; on &nbsp;
                                                      {moment(this.state.result?.updated_at).format('ll')}&nbsp;and are currently being processed.</span>}

                                                      {this.state.result?.state=="success" && <span> {this.state.result?.datapoint_count}&nbsp; rows were collected for {moment(this.state.result?.mission_start_at).format('ll')}
                                                    &nbsp;through &nbsp;{moment(this.state.result?.mission_end_at).format('ll')}&nbsp; on &nbsp;
                                                      {moment(this.state.result?.updated_at).format('ll')}</span>}

                                                    {this.state.result?.state=="failure" && <span>Data collection for {moment(this.state.result?.mission_start_at).format('ll')}
                                                    &nbsp;through &nbsp;{moment(this.state.result?.mission_end_at).format('ll')}&nbsp;
                                                    was unsuccessful as of {moment(this.state.result?.updated_at).format('ll')}. Another attempt will be made later today.</span>}

                                                    {this.state.result?.state!="new" && this.state.result?.state!="in_progress" &&
                                                    this.state.result?.state!="connected" && 
                                                    this.state.result?.state!="collected" &&
                                                    this.state.result?.state!="success" &&
                                                    this.state.result?.state!="failure" &&<span>CollectionMission#summary encountered unrecognized state:&nbsp;{this.state.result?.state} </span>}
                                                </div>
                                            </div>
                                        </div>}
</div>
                                    <div className="row">

                                    {this.state.result?.message &&<div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Message</b>
                                                </div>
                                                <div className="card-body">
                                                 {this.state.result?.message}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.triggered_by && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Triggered By</b>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.result?.triggered_by}
                                                </div>
                                            </div>
                                        </div>}
                                       {this.state.result?.created_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Created At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.created_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                       {this.state.result?.updated_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Updated  At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.updated_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}

                                    </div>



                                    <div className="row">
                                       {this.state.result?.started_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Scheduled At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.started_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.earliest_data_at &&<div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Earliest Data At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.earliest_data_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                       {this.state.result?.latest_data_at && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Latest Data  At</b>
                                                </div>
                                                <div className="card-body">
                                                    {ConvertDateTime(this.state.result?.latest_data_at, 'LLL')}
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.result?.interactive && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Interactive</b>
                                                </div>
                                                <div className="card-body">
                                                    <i className="fas fa-check text-success"></i> Yes</div>
                                            </div>
                                        </div>
                                        }
                                        {this.state.result?.rerun && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Rerun</b>
                                                </div>
                                                <div className="card-body">
                                                    <i className="fas fa-check text-success"></i> Yes</div>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Actions</b>
                                                </div>
                                                <div className="card-body">
                                                {(this.state.result?.state === "success" || this.state.result?.state === "failure") && <button onClick={() => this.rerunMission(this.state.result?.id)} className="btn btn-sm  btn-info">
                                                        Run Again
                                      </button>}

                                                    {(this.state.result?.state !== "success" && this.state.result?.state !== "failure") && <button onClick={() => this.cancelMission(this.state.result?.id)}
                                                        className="btn btn-sm   btn-danger">
                                                        Cancel
                              </button>}

                                                </div>
                                            </div>
                                        </div>
                                       {this.state.result?.target_type && this.state.result?.target_id!=0 && this.state.result?.target_name && <div className="col-3">
                                            <div className="card card-silver shadow-sm">
                                                <div className="card-header">
                                                    <b>Target</b>
                                                </div>
                                                <div className="card-body">                                                    
                                                {this.state.result?.target_type=="InboundFile" && <NavLink to={"/inbound-file/detail/" + this.state.result?.target_id}>{this.state.result?.target_name}</NavLink>}
                                                {this.state.result?.target_type=="UploadedFile" && <a href="#">{this.state.result?.target_name}</a>}
                                                {this.state.result?.target_type=="InboundEmailMessage" && <NavLink to={"/inbound-email-message/detail/" + this.state.result?.target_id}>{this.state.result?.target_name}</NavLink>}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Transitions</h5>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Time</th>
                                                            <th scope="col">Event</th>
                                                            <th scope="col">From</th>
                                                            <th scope="col">To</th>
                                                            <th scope="col">Message</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.result?.transitions && <tbody>
                                                        {this.state.result?.transitions.map((item: TransitionsModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                     <td><NavLink to={ "/collection-mission-state-transition/" + item.id}>{ConvertDateTime(item.created_at, 'LLL')}</NavLink></td>
                                                                    <td>{item.event == "" ? "--" : item.event}</td>
                                                                    <td>{item.from_}</td>
                                                                    <td>{item.to}</td>
                                                                    <td>{item.message == "" ? "--" : item.message}</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showMissionTaskModal} onHide={this.handleMissionTaskClose}>
                                        <CollectionMissionTaskFiles taskId={this.state.selectedTaskId}  handleClose={this.handleMissionTaskClose}   />
                                    </Modal>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">Tasks</h5>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Task
                                                            </th>
                                                            <th>
                                                                Created/Scheduled (UTC)
                                                            </th>
                                                            <th>
                                                                Success
                                                            </th>
                                                            <th>
                                                                Auth Failure?
                                                            </th>
                                                            <th>
                                                                Exception Count
                                                            </th>
                                                            <th>
                                                                Number of Attempts
                                                            </th>
                                                            <th>
                                                                Options
                                                            </th>
                                                            <th>
                                                                Message
                                                            </th>
                                                            <th>
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.tasks.records && <tbody>
                                                        {this.state.tasks.records.map((task, idx)=>{
                                                            return (
                                                                <tr key={idx}>
                                                                   <td><NavLink to={"/collection-mission-task/" + task.id}>{task.name}</NavLink></td>
                                                                    <td>
                                                                        {task.created_at}
                                                                    </td>
                                                                    <td>
                                                                        {task.success ? "Yes":"No"}
                                                                    </td>
                                                                    <td>
                                                                        {task.auth_failure ? "Yes":"No"}
                                                                    </td>
                                                                    <td>
                                                                        {task.exception_count}
                                                                    </td>
                                                                    <td>
                                                                        {task.num_attempts}
                                                                    </td>
                                                                    <td>
                                                                        {task.options}
                                                                    </td>
                                                                    <td>
                                                                        {task.message}
                                                                    </td>
                                                                    <td>
                                                                        <button onClick={(e) => this.handleTaskClick(task.id, e)} className="btn btn-sm  btn-info">
                                                                            View Files
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.result?.new_collection_jobs.length!=0 &&<div className="row">
                                        <div className="col-md-12">
                                            <h5 className="mt-4">New Collection Jobs </h5>
                                            <div className="table-responsive">
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Scope</th>
                                                            <th scope="col">State</th>
                                                            <th scope="col">Created At</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.result?.new_collection_jobs && <tbody>
                                                        {this.state.result?.new_collection_jobs.map((item: NewCollectionJbsModel, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                     <td><NavLink to={"/data-engine-job/detail/" + item.id}>{item.id}</NavLink></td>
                                                          {item.scope &&  <td><NavLink to={"/scope/detail/" + item.scope.value}>{item.scope.text}</NavLink></td>}
                                                          {!item.scope &&<td></td>}
                                                            <td>{item.state}</td>
                                                            <td> {ConvertDateTime(this.state.result?.created_at, 'LLL')}</td>
                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>}

                                </>}
                                {this.state.isLoading && <Loader loading={this.state.isLoading} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
